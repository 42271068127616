<template>
  <div id="user-list">
    <v-row class="mb-5"></v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            Search &amp; Filter
          </v-col>
          <v-col
            align="end"
            class="pb-0"
          >
            <v-tooltip
              v-if="$can('create', 'News')"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  fab
                  x-small
                  elevation="4"
                  color="primary"
                  :to="{ name: 'news-create' }"
                  v-on="on"
                >
                  <v-icon>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Add New News Article</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-row class="px-2 py-2 ma-0">
        <v-col
          cols="12"
          sm="4"
        >
          <v-select
            v-model.number="newsCategoriesFilter"
            :items="selectOptions.categories"
            :label="t('Filter News: Category')"
            item-text="title"
            item-value="value"
            clearable
            dense
            hide-details
            open-on-clear
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <date-time-picker
            v-model="newsDateFilter"
            :date-time="newsDateFilter"
            dense
            show-current
            input-name="date"
            :input-label="t('Filter News: Date')"
            hide-details
            @update:dateTime="val => newsDateFilter = val"
          />
        </v-col>

        <!-- <v-col align="end">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                fab
                x-small
                elevation="4"
                color="primary"
                :to="{ name: 'news-create' }"
                v-on="on"
              >
                <v-icon>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>Add New News Article</span>
          </v-tooltip>
        </v-col> -->
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <vuex-table
        :headers="tableColumns"
        :items="news"
        :meta.sync="meta"
        :options="options"
        :search.sync="search"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [5, 10, 25, 50, 100]
        }"
        @update:options="o => options = o"
      >
        <!-- title -->
        <template #[`item.title`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.thumbUrl ? '' : 'primary'"
              :class="item.thumbUrl ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.thumbUrl"
                :src="item.thumbUrl"
              ></v-img>
              <span
                v-else
                class="font-weight-medium"
              >{{ avatarText(item.title) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'news-view', params : { id : item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.title | textEllipsis(120) }}
              </router-link>
              <small>{{ item.subtitle | textEllipsis(100) }}</small>
            </div>
          </div>
        </template>

        <!-- content -->
        <template #[`item.content`]="{ item }">
          {{ item.content | stripTags | textEllipsis(100) }}
        </template>

        <!-- visibility -->
        <template #[`item.visibility`]="{ item }">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-icon
                class="rounded-0"
                :color="item.visibility ? 'primary' : 'error'"
                v-bind="attrs"
                v-on="on"
              >
                {{ item.visibility ? icons.mdiEyeCheckOutline : icons.mdiEyeOffOutline }}
              </v-icon>
            </template>
            <span v-if="item.visibility">{{ t('Visible') }}</span>
            <span v-else>{{ t('Hidden') }}</span>
          </v-tooltip>
        </template>

        <!-- content -->
        <template #[`item.categories`]="{ item }">
          <v-chip-group column>
            <v-chip
              v-for="category in item.categories"
              :key="`news-${item.id}-category-${category.id}`"
              small
              link
              :to="{ name: 'news-category-list', params: { id: category.id }}"
            >
              {{ category.name }}
            </v-chip>
          </v-chip-group>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="me-1"
                v-bind="attrs"
                :to="{ name: 'news-view', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiEyeOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>View News</span>
          </v-tooltip>

          <v-tooltip
            v-if="$can('update', 'News')"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                class="me-1"
                v-bind="attrs"
                :to="{ name: 'news-edit', params: { id: item.id } }"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <span>Edit News</span>
          </v-tooltip>

          <v-tooltip
            v-if="$can('destroy', 'News')"
            bottom
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                small
                color="error"
                v-bind="attrs"
                :disabled="deleting"
                :loading="isDialogOpen"
                @click="selectNewsToDelete(item)"
                v-on="on"
              >
                <v-icon size="18">
                  {{ icons.mdiDeleteOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span>Delete News</span>
          </v-tooltip>
        </template>
      </vuex-table>
    </v-card>

    <news-delete-dialog
      v-if="$can('destroy', 'News')"
      :data="selectedNews"
      :deleting="deleting"
      :is-dialog-open.sync="isDialogOpen"
    ></news-delete-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeCheckOutline, mdiEyeOffOutline, mdiPlus, mdiPencil, mdiDeleteOutline } from '@mdi/js'

// eslint-disable-next-line object-curly-newline
import { ref, computed, getCurrentInstance, onMounted, onUnmounted } from '@vue/composition-api'

import { avatarText } from '@core/utils/filter'
import { useUtils } from '@/@core/libs/i18n'
import useNewsList from './useNewsList'
import NewsDeleteDialog from './NewsDeleteDialog.vue'

export default {
  components: {
    NewsDeleteDialog,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { t } = useUtils()

    const isDialogOpen = ref(false)

    const deleting = computed(() => vm.$store.getters['news/deleting'])
    const selectedNews = ref(null)
    const selectNewsToDelete = news => {
      selectedNews.value = news
      isDialogOpen.value = !!news
    }

    const selectOptions = computed(() => ({
      categories: vm.$store.getters['newsCategories/items'].map(cat => ({
        value: cat.id,
        title: cat.name,
      })),
    }))

    const {
      newsListTable,
      tableColumns,
      totalNewsListTable,
      loading,

      newsTotalLocal,

      meta,
      search,
      filters,
      options,
      newsCategoriesFilter,
      newsDateFilter,

      setOptions,
      setFilters,

      news,

      init,
      destroy,
    } = useNewsList()

    onMounted(() => {
      init()
    })
    onUnmounted(() => {
      destroy()
    })

    return {
      newsListTable,
      tableColumns,
      totalNewsListTable,

      loading,
      newsTotalLocal,
      avatarText,

      setOptions,
      setFilters,

      news,
      meta,
      search,
      filters,
      options,
      newsCategoriesFilter,
      newsDateFilter,

      // icons
      icons: {
        mdiPlus,
        mdiPencil,
        mdiEyeOutline,
        mdiEyeCheckOutline,
        mdiEyeOffOutline,
        mdiDeleteOutline,
      },

      isDialogOpen,

      deleting,
      selectedNews,
      selectNewsToDelete,
      selectOptions,
      t,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
