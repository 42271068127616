import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c(VRow,{staticClass:"mb-5"}),_c(VCard,[_c(VCardTitle,[_c(VRow,[_c(VCol,[_vm._v(" Search & Filter ")]),_c(VCol,{staticClass:"pb-0",attrs:{"align":"end"}},[(_vm.$can('create', 'News'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"fab":"","x-small":"","elevation":"4","color":"primary","to":{ name: 'news-create' }}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")])],1)]}}],null,false,1592197641)},[_c('span',[_vm._v("Add New News Article")])]):_vm._e()],1)],1)],1),_c(VRow,{staticClass:"px-2 py-2 ma-0"},[_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c(VSelect,{attrs:{"items":_vm.selectOptions.categories,"label":_vm.t('Filter News: Category'),"item-text":"title","item-value":"value","clearable":"","dense":"","hide-details":"","open-on-clear":""},model:{value:(_vm.newsCategoriesFilter),callback:function ($$v) {_vm.newsCategoriesFilter=_vm._n($$v)},expression:"newsCategoriesFilter"}})],1),_c(VCol,{attrs:{"cols":"12","sm":"4"}},[_c('date-time-picker',{attrs:{"date-time":_vm.newsDateFilter,"dense":"","show-current":"","input-name":"date","input-label":_vm.t('Filter News: Date'),"hide-details":""},on:{"update:dateTime":function (val) { return _vm.newsDateFilter = val; }},model:{value:(_vm.newsDateFilter),callback:function ($$v) {_vm.newsDateFilter=$$v},expression:"newsDateFilter"}})],1)],1),_c(VDivider,{staticClass:"mt-4"}),_c('vuex-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.news,"meta":_vm.meta,"options":_vm.options,"search":_vm.search,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [5, 10, 25, 50, 100]
      }},on:{"update:meta":function($event){_vm.meta=$event},"update:search":function($event){_vm.search=$event},"update:options":function (o) { return _vm.options = o; }},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:item.thumbUrl ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.thumbUrl ? '' : 'primary',"size":"32"}},[(item.thumbUrl)?_c(VImg,{attrs:{"src":item.thumbUrl}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.title)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'news-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(_vm._f("textEllipsis")(item.title,120))+" ")]),_c('small',[_vm._v(_vm._s(_vm._f("textEllipsis")(item.subtitle,100)))])],1)],1)]}},{key:"item.content",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("textEllipsis")(_vm._f("stripTags")(item.content),100))+" ")]}},{key:"item.visibility",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({staticClass:"rounded-0",attrs:{"color":item.visibility ? 'primary' : 'error'}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(item.visibility ? _vm.icons.mdiEyeCheckOutline : _vm.icons.mdiEyeOffOutline)+" ")])]}}],null,true)},[(item.visibility)?_c('span',[_vm._v(_vm._s(_vm.t('Visible')))]):_c('span',[_vm._v(_vm._s(_vm.t('Hidden')))])])]}},{key:"item.categories",fn:function(ref){
      var item = ref.item;
return [_c(VChipGroup,{attrs:{"column":""}},_vm._l((item.categories),function(category){return _c(VChip,{key:("news-" + (item.id) + "-category-" + (category.id)),attrs:{"small":"","link":"","to":{ name: 'news-category-list', params: { id: category.id }}}},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"me-1",attrs:{"icon":"","small":"","to":{ name: 'news-view', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("View News")])]),(_vm.$can('update', 'News'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"me-1",attrs:{"icon":"","small":"","to":{ name: 'news-edit', params: { id: item.id } }}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit News")])]):_vm._e(),(_vm.$can('destroy', 'News'))?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error","disabled":_vm.deleting,"loading":_vm.isDialogOpen},on:{"click":function($event){return _vm.selectNewsToDelete(item)}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete News")])]):_vm._e()]}}],null,true)})],1),(_vm.$can('destroy', 'News'))?_c('news-delete-dialog',{attrs:{"data":_vm.selectedNews,"deleting":_vm.deleting,"is-dialog-open":_vm.isDialogOpen},on:{"update:isDialogOpen":function($event){_vm.isDialogOpen=$event},"update:is-dialog-open":function($event){_vm.isDialogOpen=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }