import {
  computed,
  getCurrentInstance,
  ref, watch,
} from '@vue/composition-api'
import { useRouter } from '@core/utils'

export default function useNewsList() {
  const vm = getCurrentInstance().proxy
  const { router, route } = useRouter()

  const newsListTable = ref([])

  const tableColumns = [
    { text: '#', value: 'id', width: '5%' },
    { text: 'TITLE', value: 'title', width: '30%' },
    { text: 'CONTENT', value: 'content', width: '15%' },
    { text: 'CATEGORIES', value: 'categories', width: '20%' },
    { text: 'DATE', value: 'date', width: '10%' },
    { text: 'VISIBILITY', value: 'visibility', width: '10%' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
      width: '10%',
    },
  ]

  const news = computed({
    get: () => vm.$store.getters['news/items'],
  })
  const loading = computed({
    get: () => vm.$store.getters['news/loading'],
  })
  const meta = computed({
    get: () => vm.$store.getters['news/meta'],
  })
  const filters = computed({
    get: () => vm.$store.getters['news/filters'],
  })
  const options = computed({
    get: () => vm.$store.getters['news/options'],
    set: val => vm.$store.dispatch('news/setOptions', { ...options.value, ...val }),
  })
  const search = computed({
    get: () => vm.$store.getters['news/search'],
    set: val => vm.$store.dispatch('news/setSearch', val),
  })
  const newsCategoriesFilter = computed({
    get: () => vm.$store.getters['news/filters']?.categoryId?.[0],
    set: val => vm.$store.dispatch('news/setFilters', { ...filters.value, categoryId: val ? [val] : val }),
  })
  const newsDateFilter = computed({
    get: () => vm.$store.getters['news/filters']?.date?.val,

    // set: val => vm.$store.dispatch('news/setFilters', { ...filters.value, date: val }),
    set: val => vm.$store.dispatch('news/setFilters', {
      ...filters.value,
      date: { column: 'date', operator: '>=', val },
    }),
  })
  const totalNewsListTable = computed({
    get: () => vm.$store.getters['news/meta'].total,
  })

  const fetchItems = () => vm.$store.dispatch('news/fetchItems')
  const setOptions = val => vm.$store.dispatch('news/setOptions', val)
  const setFilters = val => vm.$store.dispatch('news/setFilters', val)

  const init = () => {
    vm.$store.dispatch('newsCategories/fetchItems')

    if (route.value.query?.newsCategoryId) {
      newsCategoriesFilter.value = Number(route.value.query.newsCategoryId)
    }

    fetchItems()
  }

  watch(newsCategoriesFilter, (newVal, oldVal) => {
    if ((!newVal && oldVal) && route.value.query?.newsCategoryId) {
      const { newsCategoryId, ...query } = route.value.query
      router.push({
        query,
      })
    }
  })

  const destroy = () => {}

  return {
    newsListTable,
    tableColumns,

    totalNewsListTable,
    loading,

    fetchItems,

    meta,
    news,
    options,
    filters,
    search,
    newsCategoriesFilter,
    newsDateFilter,

    setOptions,
    setFilters,

    init,
    destroy,
  }
}
