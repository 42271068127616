import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c(VDialog,{attrs:{"max-width":"650px"},on:{"click:outside":function($event){return _vm.$emit('update:is-dialog-open',false)}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{staticClass:"user-edit-info pa-sm-10 pa-3"},[_c(VCardTitle,{staticClass:"justify-center text-h5 mb-5"},[_c('span',[_vm._v(_vm._s(_vm.t('Delete News Article')))]),_c('span',[_vm._v(" ")]),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(_vm.data.title))]),_c('span',[_vm._v("?")])]),_c(VCardText,{staticClass:"text-center mt-n2"},[_vm._v(" "+_vm._s(_vm.t('Are you sure you wish to delete the selected news article?'))+" ")]),_c(VCardText,{staticClass:"text-center mt-n2"},[_vm._v(" "+_vm._s(_vm.t('This action cannot be undone!'))+" ")]),_c(VCardText,{staticClass:"mt-5"},[_c(VCol,{staticClass:"d-flex justify-center mt-3",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"me-3",attrs:{"color":"error","type":"submit","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.t('Delete'))+" ")]),_c(VBtn,{attrs:{"outlined":"","color":"secondary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();_vm.isOpen = false}}},[_vm._v(" "+_vm._s(_vm.t('Discard'))+" ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }